import React, { useRef } from 'react';
import Slider from "react-slick";

import Form from './Form';
import Header from './Header';

import ancor from '../assets/images/ancor.svg';
import ruPort from '../assets/images/ru_port.svg';
import slide1 from '../assets/images/slider/1.jpg';
import slide2 from '../assets/images/slider/2.jpg';
import slide3 from '../assets/images/slider/3.jpg';
import aboutSlide1 from '../assets/images/about-slider/1.jpg';
import aboutSlide2 from '../assets/images/about-slider/2.jpg';
import aboutSlide3 from '../assets/images/about-slider/3.jpg';
import aboutSlide4 from '../assets/images/about-slider/4.jpg';
import aboutSlide5 from '../assets/images/about-slider/5.jpg';
import aboutSlide6 from '../assets/images/about-slider/6.jpg';
import aboutSlide7 from '../assets/images/about-slider/7.jpg';
import opd from '../assets/opd.pdf'

import { openVacancyModal } from '../utils/metrics';
import { changeLocationHash } from '../utils/modal';

import Money from './common/Money';

const slide = [aboutSlide1, aboutSlide2, aboutSlide3, aboutSlide4, aboutSlide5, aboutSlide6, aboutSlide7];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
};

const settingsAbout = {
    customPaging: function(i) {
        return (
            <span>
                <img src={slide[i]} alt=''/>
            </span>
        );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const Landing = ({ openModal, openStatusModal, vacancies, vacanciesError, vacanciesRefresh }) => {
    const slider = useRef(null);

    const next = () => {
        slider.current.slickNext();
    };

    const previous = () => {
        slider.current.slickPrev();
    };

    return (
        <div className="app">
            <Header />
            <div className="container">
                <div className="slider-text">
                    <h1>Остров возможностей</h1>
                </div>
            </div>
            <Slider className="header-slider" {...settings}>
                <div>
                    <img src={slide1} alt="Остров возможностей" />
                </div>
                <div>
                    <img src={slide2} alt="Остров возможностей" />
                </div>
                <div>
                    <img src={slide3} alt="Остров возможностей" />
                </div>
            </Slider>
            <div className="box-1">
                <div className="container">
                    <div className="program">
                        <div className="row">
                            <div className="col-6">
                                <b>Государственная программа по переезду и трудоустройству врачей на Сахалине</b>
                            </div>
                            <div className="col-6 bg" />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <section className="box-2">
                    <div className="container">
                        <p>На острове Сахалин острый дефицит медицинских работников. Чтобы решить проблему, правительство Сахалинской области разработало программу развития здравоохранения. В рамках программы созданы максимально комфортные и выгодные условия для привлечения врачей в область.</p>
                    </div>
                </section>
                <section className="box-3" id="program">
                    <div className="container">
                        <h2>Условия программы</h2>
                        <div className="row">
                            <div className="col-4">
                                <h4>52 000 - 150 000&nbsp;₽</h4>
                                <p>высокая заработная плата</p>
                            </div>
                            <div className="col-4">
                                <h4>до 2 600 000 ₽</h4>
                                <p>единовременная выплата на обустройство</p>
                            </div>
                            <div className="col-4">
                                <h4>до 3 000 000 ₽</h4>
                                <p>единовременная выплата на приобретение жилья</p>
                            </div>
                        </div>
                        <div className="row row-list">
                            <div className="col-4">
                                <ul>
                                    <li><span>Компенсация расходов на переезд</span></li>
                                    <li><span>Дополнительные выплаты и надбавки</span></li>
                                    <li><span>Работа в современных государственных клиниках</span></li>
                                    <li><span>Договор на 10 лет</span></li>
                                </ul>
                            </div>
                            <div className="col-4">
                                <ul>
                                    <li><span>Профессиональный коллектив</span></li>
                                    <li><span>Жизнь в живописном регионе страны</span></li>
                                    <li><span>Длительные отпуска</span></li>
                                </ul>
                            </div>
                            <div className="col-4 bg" />
                        </div>
                    </div>
                </section>

                <section className="box-4" id="about">
                    <div className="container">
                        <h2 className="mobile">Что такое Сахалин?</h2>
                    </div>
                    <Slider className="about-slider" ref={slider} {...settingsAbout}>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide1} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Богатая фауна</h3>
                                        <p>На Сахалине легко встретить серых китов. Они кормятся вблизи берега и будут не против, если вы
                                            сделаете пару кадров на память. А еще здесь живет 2000 медведей, тюлени, северные олени,
                                            росомахи и даже кабарга, — это такой маленький олень с торчащими клыками.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide2} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Этнос</h3>
                                        <p>На севере острова живет малочисленный коренной народ — нивхи.
                                            Традиционные занятия нивхов — охота и рыболовства. Будучи на Сахалине,
                                            обязательно познакомьтесь с культурой этого этноса.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide3} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Гастрономия</h3>
                                        <p>Жизнь на Сахалине — это гастрономическое приключение. Рыба и
                                            морепродукты здесь представлены в большом разнообразии. Серьезное
                                            влияние оказали корейская и японская кухни. Так что маринованного
                                            папоротника, трепангов и морских гребешков хватит на всех.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide4} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Япония</h3>
                                        <p>Мечтаете побывать в Японии? Паром до острова Хоккайдо идет всего 4,5 часа.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide5} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Рыбалка</h3>
                                        <p>Сахалинская область — один из самых рыбных регионов страны. Дело не
                                            только в Тихом океане, но и в 65000 рек. Горбуша, кета, щука, голец, сима,
                                            палтус и сотни других видов рыб ждут вас. Приезжай и лови.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide6} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Горы</h3>
                                        <p>Горный воздух — один из лучших горнолыжных курортов России. 14 трасс
                                            разного уровня сложности. Сезон длится с начала декабря до середины
                                            апреля.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide7} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Образование</h3>
                                        <p>На Сахалине 441 образовательное учреждение и никаких очередей. Ваши
                                            дети будут обучаться в лучших детских садах и школах Сахалинской области.
                                            Посещать кружки и секции.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="title-slider">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Что такое Сахалин?</h2>
                                </div>
                                <div className="col-6">
                                    <div className="btn-slider">
                                        <button className="button prev" onClick={previous} />
                                        <button className="button next" onClick={next} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="box-5" id="vacancy">
                    <div className="container">
                        <h2>Кого мы ищем </h2>
                        <div className="row list-vacancy">
                            { vacancies ? vacancies.map(({ title, minSalary, maxSalary, code }, index) => (
                                <div className="col-3" key={index}>
                                    <div className="vacancy" onClick={() => {
                                        changeLocationHash(code);
                                        openVacancyModal(code);
                                        openModal(code);
                                    }}>
                                        <a href={`#/${code}`}>
                                            <div className="name">
                                                <span>{title}</span>
                                            </div>
                                        </a>
                                        <div className="sum">
                                            <Money min={minSalary} max={maxSalary} />
                                        </div>
                                    </div>
                                </div>
                            )) : vacanciesError ? (
                                <div className="col-12 text-center">
                                    <p>Не удалось загрузить список вакансий</p>
                                    <button className="btn" onClick={() => vacanciesRefresh()}>
                                        Обновить
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </section>

                <div id="footer">
                    <section id="form" className="box-6">
                        <div id="formContainer" className="container">
                            <div className="row">
                                <div className="col-6 bg" />
                                <div className="col-6">
                                    <h3>Заполните анкету, и мы свяжемся с вами</h3>
                                    <Form
                                        openModal={openModal}
                                        openStatusModal={openStatusModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box-7">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h3>Отправляйте резюме и узнавайте подробности</h3>
                                </div>
                                <div className="col-6 contact">
                                    <div>
                                        <a href="tel:88007006807" className="phone">8 800 700 68 07</a>
                                    </div>
                                    <a href="mailto:info@med.ancor.ru" className="email">info@med.ancor.ru</a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="box-social">
                        <div className="container">
                            <a href="https://vk.com/sakh_medicina" className='social vk'><span className="icon" /></a>
                            <a href="https://www.instagram.com/med.ancor/" className='social inst'><span className="icon" /></a>
                        </div>
                    </div>

                    <footer>
                        <div className="container">
                            <img src={ancor} className="ancor" alt="ancor" />
                            <span className="pdf">
                                <a href={opd}>Политика обработки персональных данных</a>
                            </span>
                            <img src={ruPort} className="ru-port" alt="ru_port" />
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Landing);

import React from 'react';
import InputMask from 'react-input-mask';
import { Form as FinalForm, Field } from 'react-final-form';
import cx from 'classnames';

import { postVacancyResponse } from '../utils/axios';
import { getRequestPayload, validate } from '../helpers/form';
import { successModal, errorModal } from '../constants/Modal';
import { sendForm, sendFormVak } from '../utils/metrics';

const Form = ({ initialValues, isModal, closeModal, openStatusModal }) => {
    const onSubmit = async (values, form) => {
        const payload = getRequestPayload(values);

        try {
            await postVacancyResponse(payload);
            setTimeout(form.reset);
            openStatusModal(successModal);

            if (isModal) {
                sendFormVak();
                closeModal();
            } else {
                sendForm();
            }
        } catch (error) {
            openStatusModal(errorModal);
        }
    }

    return (
        <FinalForm
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name="name"
                        placeholder="ФИО"
                        render={({ input, meta, placeholder }) => (
                            <div className={cx("form-group", {'error': meta.submitFailed && meta.error })}>
                                <label htmlFor="name">Как вас зовут</label>
                                <input id="name" placeholder={placeholder} {...input} />
                                { meta.submitFailed && meta.error && <span className='field-error'>{meta.error}</span> }
                            </div>
                        )}
                    />
                    <Field
                        name="specialty"
                        placeholder=""
                        render={({ input, meta, placeholder }) => (
                            <div className={cx("form-group", {'error': meta.submitFailed && meta.error })}>
                                <label htmlFor="specialty">Ваша специализация</label>
                                <input id="specialty" placeholder={placeholder} {...input} />
                                { meta.submitFailed && meta.error && <span className='field-error'>{meta.error}</span> }
                            </div>
                        )}
                    />
                    <Field
                        name="phone"
                        placeholder="+7 (xxx) xxx-xxxx"
                        render={({ input, meta, placeholder }) => (
                            <div className={cx("form-group", {'error': meta.submitFailed && meta.error })}>
                                <label htmlFor="phone">Номер телефона</label>
                                <InputMask mask="+7 (999) 999-99-99" {...input}>
                                    {(inputProps) => (
                                        <input id="phone" placeholder={placeholder} {...inputProps} />
                                    )}
                                </InputMask>
                                { meta.submitFailed && meta.error && <span className='field-error'>{meta.error}</span> }
                            </div>
                        )}
                    />
                    <Field
                        name="additionalInfo"
                        placeholder="Укажите ваш город и время, удобное для звонка (по Москве)"
                        render={({ input, meta, placeholder }) => (
                            <div className={cx("form-group", {'error': meta.submitFailed && meta.error })}>
                                <label htmlFor="additionalInfo">Дополнительная информация</label>
                                <textarea id="additionalInfo" placeholder={placeholder} {...input} />
                                { meta.submitFailed && meta.error && <span className='field-error'>{meta.error}</span> }
                            </div>
                        )}
                    />
                    <Field
                        name="opd"
                        type="checkbox"
                        render={({ input, meta }) => {
                            const id = isModal ? "opd-modal" : "opd";

                            return (
                                <div className={cx("form-check", {'error': meta.submitFailed && meta.error })}>
                                    <input id={id} {...input}/>
                                    <label htmlFor={id}>Согласен на обработку персональных данных</label>
                                    { meta.submitFailed && meta.error && <span className='field-error'>{meta.error}</span> }
                                </div>
                            );
                        }}
                    />
                    <button
                        type="submit"
                        className="btn"
                        disabled={submitting}
                    >
                        Отправить
                    </button>
                </form>
            )}
        />
    );
}

export default Form;

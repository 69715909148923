import React, { useState } from 'react';
import { Link, animateScroll } from 'react-scroll';

import logo from '../assets/images/logo.svg';
import ancor from '../assets/images/ancor.svg';
import ruPort from '../assets/images/ru_port.svg';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(open => {
            document.body.classList.toggle('locked');
            animateScroll.scrollToTop({
                delay: 0,
                duration: 300,
            });

            return !open;
        });
    }

    return (
        <header>
                <div className="container">
                    <img src={logo} className="logo" alt="logo" />
                    <ul className="nav">
                        <li>
                            <Link activeClass="active" to="program" spy={true} smooth={true} duration={500}>
                                Условия программы
                            </Link>
                        </li>
                        <li>
                            <Link activeClass="active" to="about" spy={true} smooth={true} duration={500}>
                                О Сахалине
                            </Link>
                        </li>
                        <li>
                            <Link activeClass="active" to="vacancy" spy={true} smooth={true} duration={500}>
                                Вакансии
                            </Link>
                        </li>
                    </ul>
                    <Link to="form" spy={true} smooth={true} offset={50} duration={500} className="btn">Оставить заявку</Link>
                    <button onClick={toggleMenu} className={'hamburger-icon ' + (menuOpen ? 'active' : 'closed')}>
                        <span className="line line-1" />
                        <span className="line line-2" />
                        <span className="line line-3" />
                    </button>
                </div>
                <div className={'menu menu-' + (menuOpen ? 'open' : 'closed')}>
                    <div className="background">
                        <div className="portion" />
                        <div className="portion" />
                        <div className="portion" />
                    </div>
                    <div className="menu-content">
                        <ul className="nav">
                            <li>
                                <Link onClick={toggleMenu}  activeClass="active" to="program" spy={true} smooth={true} offset={-20} duration={500}>Условия программы</Link>
                            </li>
                            <li>
                                <Link onClick={toggleMenu} activeClass="active" to="about" spy={true} smooth={true} offset={-20} duration={500}>О Сахалине</Link>
                            </li>
                            <li>
                                <Link onClick={toggleMenu} activeClass="active" to="vacancy" spy={true} smooth={true} offset={50} duration={500}>Вакансии</Link>
                            </li>
                        </ul>
                        <div className="box-contact">
                            <div>
                                <a href="tel:88007006807" className="phone">8 800 700 68 07</a>
                            </div>
                            <a href="mailto:info@med.ancor.ru" className="email">info@med.ancor.ru</a>
                        </div>
                        <div className="box-social">
                            <a href="https://vk.com/sakh_medicina" className='social vk'><span className="icon" /></a>
                            <a href="https://www.instagram.com/sakh_medicina/" className='social inst'><span className="icon" /></a>
                        </div>
                        <div className="box-logo">
                            <img src={ancor} className="ancor" alt="ancor" />
                            <img src={ruPort} className="ru-port" alt="ru_port" />
                        </div>
                    </div>
                </div>
                <Link className="btn-fixed-container" activeClass="active" to="formContainer" spy={true} offset={-800}>
                    <Link className="btn-fixed-container" activeClass="active" to="footer" spy={true} offset={-750}>
                        <Link className="btn btn-fixed" activeClass="active" to="form" spy={true} smooth={true} offset={30} duration={500}>Оставить заявку</Link>
                    </Link>
                </Link>
            </header>
    );
}

export default Header;
